import Axios from "axios";

//EMC Gear dev
// Axios.defaults.baseURL = "https://localhost:44332/api/";

//QA new
Axios.defaults.baseURL = "https://qaapi.emcgear.in/api/";

//Prod
//  Axios.defaults.baseURL = "https://api.emcgear.in/api/";

//QA old
// Axios.defaults.baseURL = "https://qaapi.labgear.co.in/api/";

Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url, header);
  }
}
